import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
import ArrowIcon from './assets/arrow.svg';

const Arrow = styled(ArrowIcon)` 
  position: relative;
  transform: rotate(0deg);
  transition: all .2s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 25px;

  ${media.large`
    width: 30px;
  `}
`

const Wrapper = styled.button`
  background: transparent;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;

  &:hover > ${Arrow} {
    transform: rotate(45deg);
  }
`

const Label = styled.div`
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;
  margin: 0 0 0 10px;

  ${media.large`
    ${type('body')}
  `}
`



const ArrowButton = (props) => {
  const { children } = props;
  return (
    <Wrapper>
      <Arrow />
      <Label>{ children }</Label>
    </Wrapper>
  )
}

export default ArrowButton;