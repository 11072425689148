import React from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div` 
  position: relative;
  width: 100%;
`
const VideoTag = styled.video` 
  width: 100%;
`

const VideoModule = ({src,poster}) => {

  return ( 
    <VideoWrapper>
      <VideoTag playsInline autoPlay muted loop poster={poster}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoTag>
    </VideoWrapper>
  )
}

export default VideoModule;