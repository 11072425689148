import React from 'react';
import styled from 'styled-components';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import { LazyBlur, LazyBackgroundBlur } from "@manualengineering/react-lazyblur"; 
import VideoBackground from '../VideoBackground/VideoBackground';
import VideoModule from '../VideoModule/VideoModule';
import HtmlParser from '../HtmlParser/HtmlParser';
import FeaturedSelect from '../FeaturedSelect/FeaturedSelect';
import Tag from '../Tag/Tag';
import { StoreContext } from '../../context/store-context';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.section` 
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #383B23;

  ${media.large`
    border-top: none;
  `}

`
const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${props => props.isMobileVideo ? '0%' : '150%'};
  border-radius: 40px;
  overflow: hidden;
  isolation: isolate;

  ${media.large`
    padding-bottom: 49.62%;
  `}
`

const MainInner = styled.div`
  position: absolute;
  padding: 40px 20px 20px;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 100%;
  pointer-events: none;

  ${media.large`
    display: block;
    height: auto;
    padding: 30px 30px;
    pointer-events: auto;
  `}
`

const Title = styled.h2`
  ${fontstack.default}
  ${type('m-heading')}
  font-weight: 300;
  text-align: center;
  color: #383B23;
  margin: 20px 0 80px;

  ${media.large`
    ${type('large')}
    font-weight: 300;
    color: #F3EFD7;
    margin: 0 0 30px;
  `}
`

const Copy = styled.p` 
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  color: #F3EFD7;
  margin: 0;
  width: 100%;

  ${media.large`
    ${type('large')}
    font-weight: 300;
    max-width: 40%;
  `}
`

const BackgroundWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`

const Header = styled.div`
  width: 100%;

  ${media.large`
    border-bottom: 2px solid #F3EFD7;
    margin: 0 0 30px;
  `}
`

const HeaderInner = styled.div` 
  position: relative;
`

const Tags = styled.div` 
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${media.large`
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `}
`

const TagInfo = styled(Tag)`
  text-transform: uppercase;
  ${type('caption')}
  margin: 15px 7.5px 0 7.5px;

  ${media.large`
    margin: 0 7.5px 0 7.5px;
    &:first-of-type {
      margin: 0 7.5px 0 0;
    }
  `}
  
`

const BottomWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(12,1fr);
  margin: 0 0 30px;
  width: 100%;

  ${media.large`
    grid-column-gap: 30px;
    width: auto;
  `}
`

const StatementWrapper = styled.div`
  grid-column: 1 / span 12;
  grid-row: 1;

  ${media.large`
    grid-column: 9 / span 4;
  `}
`

const Statement = styled.h3`
  ${fontstack.default}
  ${type('m-large')}
  font-weight: 300;
  color: #383B23;
  margin: 20px 0 40px;
  opacity: 0;
  transform: translateY(50px);

  ${media.large`
    ${type('large')}
    font-weight: 300;
    margin: 40px 0 0;
  `}

  
`

const FeaturedSelectWrapper = styled.div`
  grid-column: span 12;
  ${media.large`
    grid-column: 5 / span 4;
  `}
`

const FeaturedSelectShifted = styled(FeaturedSelect)`
  ${media.large`
    margin-top: -100px;
  `}
`

const FeaturedCategory = (props) => {
  const { src, placeholder, video, copy, title, tags, products, name, statement, cta } = props; 
  const { width } = React.useContext(StoreContext);
  const Trigger = React.useRef();
  const cachedScrollPos = React.useRef(0);
  const [ stateRef, setStateRef ] = React.useState(null);

  React.useEffect(()=> {
    const onScroll = () => {
      const position = window.pageYOffset;
      const delta = position - cachedScrollPos.current;

      
        if(delta > 0) {
          console.log('down');
          gsap.to(stateRef, {
            y: delta*0.3,
            duration: 1,
            ease: 'elastic.out(1, 0.95)',
            onComplete: () => {
              gsap.to(stateRef, {
                y: 0,
                duration: 1,
                ease: 'elastic.out(1, 0.95)'
              });
            }
          });
        } else if(delta < 0) {  
          console.log('up');
          gsap.to(stateRef, {
            y: delta*0.3,
            duration: 1,
            ease: 'elastic.out(1, 0.95)',
            onComplete: () => {
              gsap.to(stateRef, {
                y: 0,
                duration: 1,
                ease: 'elastic.out(1, 0.95)'
              });
            }
          });
        }
        
      
      
      cachedScrollPos.current = position;
    }
    if(!Trigger.current && stateRef && width) {
      Trigger.current = ScrollTrigger.create({
        trigger: stateRef,
        start: "-200 center",
        markers: false,
        onEnter: () => {
         gsap.to(stateRef, {
            y: 0,
            opacity: 1,
            duration: 1
          });
        }
      });
      window.addEventListener('scroll',onScroll, { passive: true })
    }
    
    
    return () => {
      window.removeEventListener('scroll',onScroll)
      if(Trigger.current) {
        Trigger.current.kill();
      }
    }
  }, [stateRef, width])

  const videoContent = () => {
    if(width >= 1080 && video.desktop.src || width < 1080 && !video.mobile.src) {
      return <BackgroundWrapper><VideoBackground src={video.desktop.src} poster={null} /> </BackgroundWrapper>
    } else if(width < 1080 && video.mobile.src ) {
      return <VideoModule src={video.mobile.src} poster={null} />
    }   
  }

  const StatementRef = React.useCallback((node)=> {
    if(node !== null) {
      setStateRef(node);
    }
  }, [])

  return (
    <Wrapper>
      { width < 1080 ? <Title>{title}</Title> : null }
      <MainWrapper isMobileVideo={width < 1080 && video.mobile.src}>
        <MainInner>
          <Header>
            <HeaderInner>
              <Tags>
                {tags ? tags.map((tag) => {
                  return <TagInfo theme='light'>{tag}</TagInfo>
                }):null}
              </Tags>
              { width >= 1080 ? <Title>{title}</Title> : null }</HeaderInner>
          </Header>
          <Copy>{ copy }</Copy>
        </MainInner>
        { src ? <BackgroundWrapper><LazyBackgroundBlur src={src} placeholder={placeholder} /></BackgroundWrapper> : video ? videoContent() : null }
      </MainWrapper>
      <BottomWrapper>
        <FeaturedSelectWrapper>
          <FeaturedSelectShifted products={products} name={name}/>
        </FeaturedSelectWrapper>
        <StatementWrapper>
          <Statement ref={StatementRef}><HtmlParser>{statement}</HtmlParser></Statement>
        </StatementWrapper>
      </BottomWrapper>
      {cta || null }
    </Wrapper>
    
  )
}

export default FeaturedCategory;