import * as React from "react"
import { graphql, Link } from "gatsby"
import slugify from "@sindresorhus/slugify";
import { StoreContext } from "../context/store-context";
import { Layout } from "../components/Layout/layout"
import HomeHero from '../components/HomeHero/HomeHero';
import { Seo } from "../components/seo";
import NewsTicker from "../components/NewsTicker/NewsTicker";
import NewsTickerItem from "../components/NewsTicker/NewsTickerItem";
import NewsTickerTag from "../components/NewsTicker/NewsTickerTag";
import FeaturedCategory from "../components/FeaturedCategory/FeaturedCategory";
import CallToAction from "../components/CallToAction/CallToAction";
import OurMission from "../components/OurMission/OurMission";
import OurMissionMobile from "../components/OurMission/OurMissionMobile";
import OurSocial from "../components/OurSocial/OurSocial";

import granolaVideo from '../components/HomeHero/assets/Granola-test.mp4';
import granolaPoster from '../components/HomeHero/assets/video-poster.jpg';
import smoothieVideo from '../components/HomeHero/assets/IcebarSmoothie.mp4';
import granolaImage from '../components/FeaturedCategory/assets/granola_product.jpg';
import granolaImageBlur from '../components/FeaturedCategory/assets/granola_product_blur.jpg';
import smoothieImage from '../components/FeaturedCategory/assets/smoothie_product.jpg';
import smoothieImageBlur from '../components/FeaturedCategory/assets/smoothie_product_blur.jpg';
import ImmunityImage from '../components/FeaturedCategory/assets/Immunity_Bar.jpg';
import ImmunityImageBlur from '../components/FeaturedCategory/assets/Immunity_Bar_blur.jpg';
import DetoxImage from '../components/FeaturedCategory/assets/Detox_Bar.jpg';
import DetoxImageBlur from '../components/FeaturedCategory/assets/Detox_Bar_small.jpg';
import EnduranceImage from '../components/FeaturedCategory/assets/Endurance_Small.jpg';
import EnduranceImageBlur from '../components/FeaturedCategory/assets/Endurance_Small_blur.jpg';
import AwakeImage from '../components/FeaturedCategory/assets/Awaken_Small.jpg';
import AwakeImageBlur from '../components/FeaturedCategory/assets/Awaken_Small_blur.jpg';
import ChillaxImage from '../components/FeaturedCategory/assets/Chillax_Small.jpg';
import ChillaxImageBlur from '../components/FeaturedCategory/assets/Chillax_Small_blur.jpg';
import EnergyImage from '../components/FeaturedCategory/assets/Energy_Bar.jpg';
import EnergyImageBlur from '../components/FeaturedCategory/assets/Energy_Bar_blur.jpg';
import RecoveryImage from '../components/FeaturedCategory/assets/Recovery_Bar.jpg';
import RecoveryImageBlur from '../components/FeaturedCategory/assets/Recovery_Bar_blur.jpg';

import { ProductListing } from "../components/product-listing"
import Section from "../components/Section/Section";
import {
  container,
  intro,
  callOut,
  callToAction,
  deployButton,
} from "./index.module.css"


export default function IndexPage({ data: { homepage } }) {
  const { width } = React.useContext(StoreContext);
  const home = homepage.data;
  const { 
    hero_carousel,
    hero_image,
    hero_copy,
    hero_cta_label,
    hero_cta_link,
    newsticker_cta_label,
    newsticker_cta_link,
    newsticker_items,
    mission_statements,
    social_card_copy,
    social_card_link,
    social_cards,
    body
  } = home;
  
  return (
    <Layout>
      <Seo
          title='convenient nutritious essentials'
          description='Innovating vegan, fast, and functional foods for breakfast, snack, or dinner. Serve to kickstart you healthy and conscious lifestyle today.'
          image={hero_image.url}
        />
      <Section none>
        <HomeHero data={hero_carousel} src={hero_image.url} placeholder={`${hero_image.url}&w=100&blur=10&q=50`} copy={hero_copy.html} ctaLabel={hero_cta_label} ctaLink={hero_cta_link}/>
      </Section>

      <Section nogutter large>
        <NewsTicker ctaLabel={newsticker_cta_label} ctaLink={newsticker_cta_link} speed={'40s'}>
        { newsticker_items && newsticker_items.length > 0 ?
        newsticker_items.map((item) => {

          return [
            <NewsTickerItem count={item.sub_text}>{item.primary_text}</NewsTickerItem>,
            <NewsTickerTag>{item.tag_label}</NewsTickerTag>
          ]
            
        }) : null }
      </NewsTicker>
      </Section>
      { body && body.length > 0 ?
      body.map((module)=> {
        const {
          title,
          tags,
          primary_copy,
          secondary_copy,
          cta_link,
          cta_label,
          desktop_video,
          mobile_video,
          image1
        } = module.primary
        console.log(module.items);
        const products = module.items.reduce((acc,curr) => {
          const url = curr.shopify_product.product_type ? `/products/${curr.shopify_product.product_type}/${curr.shopify_product.handle}` : `/products/${curr.shopify_product.handle}`
          const product = { 
            title: curr.shopify_product.title, 
            tag: curr.shopify_product.fields.subtitle,
            url: url, 
            image: { 
              alt: curr.product_image ? curr.product_image.alt : null,
              src: curr.product_image ? curr.product_image.url : null, 
              thumbnails: curr.product_image ? curr.product_image.thumbnails : null,
              placeholder: { 
                src: curr.product_image ? `${curr.product_image.url}&w=100&blur=10&q=50` : null, 
                width: curr.product_image ? curr.product_image.dimensions.width : null, 
                height: curr.product_image ? curr.product_image.dimensions.height : null
              }
            }
          }

          acc.push(product);

          return acc;
        },[]);

        return  <Section>
        <FeaturedCategory 
          video={{
            desktop:{
              src: desktop_video.url,
              poster: null
            },
            mobile:{
              src: mobile_video.url,
              poster: null
            }
          }}
          src={image1.url}
          placeholder={{
            src:image1.url ? `${image1.url}&w=100&blur=10&q=50` : null,
            width: image1.dimensions ? image1.dimensions.width : null,
            height: image1.dimensions ? image1.dimensions.height : null
          }}
          title={title}
          tags={tags ? tags.split(',') : null}
          products={products}
          name={slugify(title)}
          copy={primary_copy}
          statement={secondary_copy}
          cta={<Link to={cta_link}><CallToAction>{cta_label}</CallToAction></Link>}/>
      </Section>

      }) : null }
      <Section none>
        { width >= 1080 ? <OurMission statements={mission_statements}/> : <OurMissionMobile statements={mission_statements}/>}
      </Section>
      <Section none>
        <OurSocial data={{
          social_card_copy,
          social_card_link,
          social_cards
        }}/>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    homepage: prismicHomepage {
      data {
        hero_carousel {
          image {
            url(imgixParams: {q: 90, width: 3000})
            dimensions {
              height
              width
            }
            alt
            thumbnails {
              mobile {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
            }
          }
          copy {
            html
          }
          cta_label
          cta_link
        }
        hero_image {
          url(imgixParams: {q: 90, width: 3000})
          dimensions {
            height
            width
          }
          alt
          thumbnails {
            mobile {
              url
              dimensions {
                height
                width
              }
              alt
            }
          }
        }
        hero_copy {
          html
        }
        hero_cta_label
        hero_cta_link
        newsticker_cta_label
        newsticker_cta_link
        newsticker_items {
          primary_text
          sub_text
          tag_label
        }
        mission_statements {
          statement
          image1 {
            alt
            dimensions {
              height
              width
            }
            url(imgixParams: {width: 600, q: 80})
          }
        }
        social_card_copy
        social_card_link {
          url
        }
        social_cards {
          image1 {
            alt
            dimensions {
              height
              width
            }
            url(imgixParams: {width: 600, q: 80})
          }
          instagram_link {
            url
          }
        }
        body {
          ... on PrismicHomepageDataBodyFeaturedCategory {
            items {
              shopify_product {
                fields {
                  subtitle
                }
                product_type
                title
                handle
              }
              product_image {
                url(imgixParams: {width: 1200, q: 80})
                alt
                dimensions {
                  height
                  width
                }
                thumbnails {
                  mobile {
                    url
                    alt
                    dimensions {
                      height
                      width
                    }
                  }
                }
              }
            }
            primary {
              cta_label
              cta_link
              primary_copy
              secondary_copy
              tags
              title
              desktop_video {
                url
              }
              mobile_video {
                url
              }
              image1 {
                url(imgixParams: {q: 80, width: 1500})
                alt
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
