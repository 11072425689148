import React from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div` 
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`
const VideoTag = styled.video` 
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const VideoBackground = ({src,poster}) => {

  return ( 
    <VideoWrapper>
      <VideoTag playsInline autoPlay muted loop poster={poster}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoTag>
    </VideoWrapper>
  )
}

export default VideoBackground;