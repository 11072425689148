import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import { gsap } from "gsap";
import { Link } from 'gatsby';
import { StoreContext } from '../../context/store-context';
import ImageBlock from '../ImageBlock/ImageBlock';
import ProductRadio, { ProductRadioItem } from '../ProductRadio/ProductRadio';
import Tag from '../Tag/Tag';
import { media } from '../../utils/mediaQuery';
// Import Swiper styles
import "swiper/css";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Items = styled.div`
  position: relative;
  width: 70%;
  margin: 0 0 40px;
  ${media.large`
    margin: 0 0 30px;
    width: 100%;
  `}
`

const Scrollable = styled.div`
  max-width: 70%;
  ${media.medium`
    max-width: initial;
    overflow-x: auto;
  `}
  
`;

const ProductRadioCustom = styled(ProductRadio)`
  display: block;
`

const FeaturedSelect = (props) => {
  const { products, name, ...rest } = props;
  const [ Active, setActive ] = useState(products[0].title.toLowerCase());
  const [ Pin, setPin ] = useState(Active); 
  const [ stateRef, setStateRef ] = useState();


  const onChange = (event) => {
    const val = event.target.value;
    setActive(val);
    console.log(val);
    setTimeout(()=>{
      setPin(val);
    },200);
  }

  const onMouseEnter = () => {
    if(stateRef) {
      gsap.to(stateRef, {
        scale: 0.99,
        duration: 1,
        ease: 'elastic.out(1, 0.3)'
      });
    }
    
  }

  const onMouseLeave = () => {
    if(stateRef) {
      gsap.to(stateRef, {
        scale: 1,
        duration: 1,
        ease: 'elastic.out(1, 0.3)'
      });
    }
  }

  const ItemsRef = React.useCallback((node) => {
    if(node !== null) {
      setStateRef(node);
    }
  }, [])

  return (
    <Wrapper {...rest}>
      <Items ref={ItemsRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      { products ? products.map((product) => {
          const { title, tag, url, image } = product; 
          return <FeaturedSelectedItem to={url} tag={tag} image={image} active={Active===title.toLowerCase() ? true : false} pin={Pin===title.toLowerCase() ? true : false}/>;
        }) : null}
      </Items>
      <Scrollable>
        <ProductRadioCustom onChange={onChange} bypass>
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={0}
            className="mySwiper"
            style={{marginLeft:'0',marginRight:'0',overflow:'visible'}}
          >
          { products ? products.map((product,i) => {
            const { title } = product; 
            return <SwiperSlide style={{width: 'auto'}}><ProductRadioItem name={name} value={title.toLowerCase()} checked={Active===title.toLowerCase() ? true : false}>{title}</ProductRadioItem></SwiperSlide>
          }) : null}
          </Swiper>
        </ProductRadioCustom>
      </Scrollable>
    </Wrapper>
  )

}

export default FeaturedSelect;

const ItemBase = styled.div` 
  position: relative;
  &:not(:first-of-type) {
    position: absolute;
    top: 0;
    left: 0;
  }
  width: 100%;
  opacity: ${props => props.active || props.pin ? '1' : '0'};
  transition: opacity .2s ease;
  z-index: ${props => props.active ? '2' : props.pin ? '1' : '0'};
`

const ItemWrapper = styled.div` 
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  
`

const Image = styled.img` 
  position: relative;
  width: 100%;
  display: block;
`

const Content = styled.div`
  padding: 30px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
`

const TagWrapper = styled.div`
  position: relative;
  margin: 20px 0 0;
  display: flex;
  justify-content: center;
  background: #F2EADE;
`

const FeaturedSelectedItem = (props) => {
  const { tag, image, active, pin, to } = props;
  const { width } = React.useContext(StoreContext);

  return (
    <ItemBase active={active} pin={pin}>
    <ItemWrapper >
      <Link to={to}>
        <Content>
           { width >= 1080 ? <Tag theme='outline'>{tag}</Tag> : null }
        </Content>
        { width < 1080 && image.thumbnails.mobile ?
        <ImageBlock key='test' src={image.thumbnails.mobile.url} placeholder={{
          src: `${image.thumbnails.mobile.url}&w=100&blur=10&q=50`,
          width: image.thumbnails.mobile.dimensions.width,
          height: image.thumbnails.mobile.dimensions.height
        }} alt={image.thumbnails.mobile.alt}/> : 
          <ImageBlock key='test2' src={image.src} placeholder={image.placeholder} alt={image.alt}/>
         }
  
      </Link>
    </ItemWrapper>
    { width < 1080 ? 
      <TagWrapper>
        <Tag theme='outline'>{tag}</Tag>
      </TagWrapper> : null
    }
    </ItemBase>
  )
}