import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'gatsby'
import { LazyBackgroundBlur } from '@manualengineering/react-lazyblur';
import HtmlParser from '../HtmlParser/HtmlParser';
import SwiperArrow from "../SwiperArrow/SwiperArrow";
import CallToAction from '../CallToAction/CallToAction';
import VideoBackground from '../VideoBackground/VideoBackground';
import DownArrow from './assets/down-arrow.svg';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';
// import required modules
import { EffectFade, Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;


  ${media.large`
    height: calc(100vh - 110px);
  `}
`

const WrapperInner = styled.div` 
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
  flex: 1;

  ${media.large`
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  `}    
`

const ArrowWrapper = styled.div` 
  width: 100%;
  height: 60px;
  align-items: flex-end;
  display: flex;
  justify-content: center;

  ${media.large`
    display: none;
  `}
`

const Content = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  ${media.large`
    padding: 30px;
    justify-content: flex-start;
  `}
`
const Statement = styled.h2`
  ${fontstack.default}
  ${type('m-heading')}
  font-weight: 300;
  color: ${props => props.theme === "light" ? '#383B23' : '#F3EFD7'};
  margin: 0 0 30px;
  text-align: center;

  ${media.large`
    ${type('heading')}
    font-weight: 300;
    text-align: left;
  `}
  
`

const CustomSwiper = styled(Swiper)`
  position: relative;
  width: 100%;
  height: 100%;
`

const HomeHero = (props) => {
  const { data, src, placeholder, copy, ctaLabel, ctaLink, videosrc, videoposter } = props;
  const [_, setInit] = useState(false);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <>
    <Wrapper>
      <WrapperInner>
        <CustomSwiper
          onInit={() => setInit(true)}
          effect={'fade'}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          modules={[Autoplay, Navigation, EffectFade]}
        >
          { data && data.map((slideContent,i) => {
            return (
              <SwiperSlide key={i}>
                <Content>
                  { slideContent.copy?.html ? <Statement theme="dark"><HtmlParser strip={['p']}>{slideContent.copy?.html}</HtmlParser></Statement> : null }
                  { slideContent.cta_link ? <Link to={slideContent.cta_link}><CallToAction theme="dark">{slideContent.cta_label}</CallToAction></Link> : null }
                </Content>
                { slideContent.image ? <LazyBackgroundBlur src={slideContent.image.url} placeholder={`${slideContent.image.url}&w=100&blur=10&q=50`}></LazyBackgroundBlur> : videosrc ?
                <VideoBackground src={videosrc} poster={videoposter} /> : null }
            </SwiperSlide>
            )
          })}
          <SwiperArrow ref={navigationPrevRef} reverse/>
          <SwiperArrow ref={navigationNextRef}/>
        </CustomSwiper>
      </WrapperInner>
      <ArrowWrapper>
        <DownArrow />
      </ArrowWrapper>
    </Wrapper>
    </>
  )

}

export default HomeHero;
