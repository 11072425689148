import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { media } from '../../utils/mediaQuery';
import Tag from '../Tag/Tag';

const Wrapper = styled.div`
  position: relative;
  margin: 0 30px;
  display:flex;
  align-items: flex-end;
  height: 100%;
  padding: 0 0 4px;

  ${media.large`
    margin: 0 70px;
    padding: 10px 0 0;
    align-items: center;
  `}
`

const NewsTickerTag = (props) => {
  const { children, ...rest } = props;


  return (
    <Wrapper>
      <Tag {...rest}>
        { children }
      </Tag>
    </Wrapper>
  )

}

export default NewsTickerTag;