import React from 'react';
import styled from 'styled-components';
import ImageBlock from '../ImageBlock/ImageBlock';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';



const SocialBox = (props) => {
  const { children, label, src, placeholder, alt, blur, ...rest } = props;
  

  return (
    <>
      { children ? <SocialBoxText label={label} blur={blur} {...rest}>{children}</SocialBoxText> : src ? <SocialBoxImage src={src} placeholder={placeholder} alt={alt} blur={blur} {...rest}/> : null }
    </>
  )
}

export default SocialBox;


const SocialImageWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  opacity: ${props => props.blur ? '0.8' : '1'};
  filter: ${props => props.blur ? 'blur(4px)' : 'blur(0px)'};
  transition: filter .6s ease, opacity .6s ease;
`


const SocialBoxImage = (props) => {
  const { src, placeholder, alt, blur, ...rest} = props;
  return (
    <SocialImageWrapper blur={blur} {...rest}>
      <ImageBlock src={src} placeholder={placeholder} alt={alt} />
    </SocialImageWrapper>
  )
}


const SocialBoxTextWrapper = styled.div`
  position: relative;
  border: 1px solid #383B23;
  border-radius: 10px;;
  padding-bottom: 100%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  opacity: ${props => props.blur ? '0.8' : '1'};
  filter: ${props => props.blur ? 'blur(4px)' : 'blur(0px)'};
  transition: filter .6s ease, opacity .6s ease;
`

const SocialBoxTextInner = styled.div`
  position: absolute;
  left: 0;
  top: 0; 
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const SocialBoxTextContent = styled.div`
  ${fontstack.default}
  ${type('m-body')}
  font-weight: 300;
  color: #383B23;

  ${media.large`
    ${type('body')}
  `}

  ${media.xlarge`
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    letter-spacing:0.030em;
  `}
`

const SocialBoxTextLabel = styled.div`
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;

  ${media.large`
    ${type('body')}
  `}

  ${media.xlarge`
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    letter-spacing:0.030em;
  `}
`

const SocialBoxText = (props) => {
  const { children, label, blur, ...rest } = props;
  return (
    <SocialBoxTextWrapper blur={blur} {...rest}>
      <SocialBoxTextInner>
        <SocialBoxTextContent>
          {children}
        </SocialBoxTextContent>
        <SocialBoxTextLabel>
          {label}
        </SocialBoxTextLabel>
      </SocialBoxTextInner>
    </SocialBoxTextWrapper>
  )
}