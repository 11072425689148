import React, { useState, useRef, useEffect } from 'react';
import { gsap } from "gsap";
import styled from 'styled-components';
import HtmlParser from '../HtmlParser/HtmlParser';
import ModuleDivider from '../ModuleDivider/ModuleDivider';
import { fontstack } from '../../utils/fontstack';

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const Main = styled.div` 
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  isolation: isolate;
  padding: 0 8vw;
`

const OurMission = ({statements}) => {
  const Animation = useRef();
  const MainEl = useRef();
  const ThumbEl = useRef();
  const FirstEl = useRef();
  const Snap = useRef(false);
  const position = useRef({x:0,y:0});
  const [ ThumbPosition, setThumbPosition ] = useState({});
  const [ Active, setActive ] = useState(false);

  useEffect(() => {
    const mainElInstance = MainEl.current;
    const AnimationInstance = position.current;
    MainEl.current.addEventListener('mousemove', onMouseMove)
    
    return () => {
      mainElInstance.removeEventListener('mousemove', onMouseMove);
      if(AnimationInstance) {
        gsap.killTweensOf(AnimationInstance);
      }
    }
  },[])

  const onMouseMove = (e) => {
    const boundingBox = MainEl.current.getBoundingClientRect();
    const x = (e.clientX - boundingBox.x) - (ThumbEl.current.offsetWidth/2);
    const y = (e.clientY - boundingBox.y) - (ThumbEl.current.offsetHeight/2);


    if(!Snap.current) {
      animateTo(x,y);
    } else {
      animateTo(Snap.current.x,Snap.current.y);
    }
  }

  const animateTo = (x,y) => {
    Animation.current = gsap.to(position.current, 
      { 
        duration: 3,
        ease: 'elastic.out(.8, 0.1)',
        y: y,
        x: x,
        onUpdate: () => {
          ThumbEl.current.style.transform = `translate3d(${position.current.x}px,${position.current.y}px, 0px)`;
        },
        onComplete: () => {

        }
      }
    );
  }


  const onMouseEnter = (index) => {
    setActive(index);
    Snap.current = false;
  }

  const snapToFirst = () => {
    const boundingBoxMain = MainEl.current.getBoundingClientRect();
    const boundingBoxFirst = FirstEl.current.getBoundingClientRect();
    const x = ((boundingBoxMain.right - boundingBoxMain.left) / 2) - (ThumbEl.current.offsetWidth/2);
    const y = ((boundingBoxFirst.top - boundingBoxMain.top ) + boundingBoxFirst.height / 2) - (ThumbEl.current.offsetHeight/2);
    Snap.current = { x: x, y: y };
  }

  const onMouseLeave = () => {
    setActive(null);
  }       

  const onMainLeave = () => {
    setActive(null);
  }

  return (
    <Wrapper>
    <ModuleDivider>Our Mission</ModuleDivider>
    <Main ref={MainEl} onMouseLeave={onMainLeave}>
      { statements.map((item, index) => {
        return <MissionItem ref={index === 0 ? FirstEl : null} onMouseEnter={()=>onMouseEnter(index)} onMouseLeave={onMouseLeave} active={Active === index ? true : false}><HtmlParser>{item.statement}</HtmlParser></MissionItem>
      }) }
      <ThumbWrapper active={true}>
        <Thumb ref={ThumbEl} >
          {statements.map((item, index) => {
            return <ThumbSource first={index === 0 ? true : false} src={item.image1.url} active={Active === index ? true : false}/>
          })}
        </Thumb>
      </ThumbWrapper>
    </Main>
    </Wrapper>
  )
}

export default OurMission;


const ItemWrapper = styled.div`
  position: relative;
  padding: 0.8vw 0;
  z-index: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  mix-blend-mode: color-dodge;
`

const Statement = styled.div`
  ${fontstack.default}
  //border-bottom: 0.2vw solid ${props => props.active ? '#383B23' : '#383B2300'};
  font-size: 4vw;
  font-weight: 300;
  letter-spacing: 0.030em;
  color: ${props => props.active ? '#383B23' : '#383B2320'};
  position: relative;
  margin: ${props => props.active ? '0 0 0 0' : '0 0 0 0'};
  transition: all .4s ease;
  z-index: 1;
  text-align: center;
`

const Bullet = styled.div` 
  position: absolute;
  left: 0;
  top: 50%; 
  display: flex;
  align-items: center;
  mix-blend-mode: screen;
  z-index: 1;
  transition: opacity .2s ease;
  opacity: 0;
  &:before {
    content: '';
    display: block;
    background: #383B23;
    border-radius: 50%;
    width: 2vw;
    height: 2vw;
    margin-top: -50%;
    margin-left: -175%;
  }
`

const ThumbSource = styled.img`
  width: 100%;
  position: ${props => props.first ? 'relative' : 'absolute'};
  left: 0;
  top: 0;
  display: block;
  overflow: hidden;
  border-radius: 20px;
  opacity: ${props=> props.active ? '1' : '0'};
  z-index: ${props=> props.active ? '1' : '0'};
  transition: opacity .6s ease;
`

const Thumb = styled.div`
  width: 30%;
  position: absolute;
`

const ThumbWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: ${props => props.active ? '1' : '0'};
  z-index: 0;
  transition: opacity .2s ease;
`


const MissionItem = React.forwardRef((props,ref) => {
  const { children, active, ...rest } = props;

  return (
    <ItemWrapper ref={ref} {...rest}>
      <Bullet active={active}/>
      <Statement active={active}>
        { children }
      </Statement>
    </ItemWrapper>
    
  )
})
