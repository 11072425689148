import React from 'react';
import styled from 'styled-components';
import { media } from '../../utils/mediaQuery';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';


const Wrapper = styled.div`
  display: flex;
  position: relative;
`
const ProductRadio = (props) => {
  const { children, name, callback, bypass,...rest } = props;

  const onChange = (event) => {
    const value = event.target.value;
    if(callback) {
      callback(value);
    }
  }

  const radios = bypass ? children : React.Children.map(children, child => {
    if(React.isValidElement(child)) {
      return React.cloneElement(child,{ name:name, onChange: onChange});
    }
    return child;
  });

  return (
    <Wrapper {...rest}>
      {radios}
    </Wrapper>
  )
}

export default ProductRadio;


const Label = styled.div`
  opacity: 0.2;
  transition: opacity .2s ease;
  word-break: keep-all;
`
const DotWrap = styled.div` 
  position: relative;
  overflow: hidden;
`

const Dot = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #383B23;
  border-radius: 50%;
  margin: 10px 0 0;
  opacity: 1;
  transition: opacity .2s ease, transform .2s ease;
  transform: translate(0,-20px);
`

const LabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${fontstack.default}
  ${type('m-body')}
  color: #383B23;
  padding: 0 15px;
  user-select: none;
  cursor: pointer;
  flex-shrink: 0;

  ${media.large`
    ${type('body')}
  `}

  ${media.medium`
    &:hover > ${DotWrap} > ${Dot} {
      transform: translate(0,0px);
    }

    &:hover > ${Label} {
      opacity: 1;
    }
  `}

`

const Input = styled.input`
  display: none;

  &:checked ~ ${DotWrap} > ${Dot} {
    transform: translate(0,0px);
  }

  &:checked ~ ${Label} {
    opacity: 1;
  }

`

const ProductRadioItem = (props) => {
  const { value, children, ...rest } = props;

  return (
    <>
    <LabelWrapper>
      <Input type="radio" value={value} {...rest}/>
      <Label>{children}</Label>
      <DotWrap>
        <Dot />
      </DotWrap>
    </LabelWrapper>
    </>
  )
} 

export { ProductRadioItem };