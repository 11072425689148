import React, { useState } from 'react';
import styled from 'styled-components';
import ModuleDivider from '../ModuleDivider/ModuleDivider';
import Section from '../Section/Section';
import SocialBox from '../SocialBox/SocialBox';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.section`
  position: relative;
  width: 100%;
`

const Inner = styled(Section)`
  overflow: visible;
`

const SocialBoxes = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  ${media.large`
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;
  `}
`

const OurSocial = ({data}) => {
  const cards = data.social_cards;
  const [ Active, setActive ] = useState(null);
  return (
    <Wrapper>
      <ModuleDivider>Our Social</ModuleDivider>
      <Inner nogutter>
        <SocialBoxes>
            <a href={data.social_card_link.url} target="__blank"><SocialBox onMouseEnter={()=>{ setActive(0)}} onMouseLeave={()=>{ setActive(null)}} label="@bynute" blur={Active !== null && Active !== 0 ? true : false}>{data.social_card_copy}</SocialBox></a>
            {cards && cards.length > 0 ?
            cards.map((card, index) => {
              return <a href={card.instagram_link.url} target="__blank"><SocialBox onMouseEnter={()=>{ setActive(index+1)}} onMouseLeave={()=>{ setActive(null)}} blur={Active !== null && Active !== index+1 ? true : false} src={card.image1.url} placeholder={{
                src: `${card.image1.url}&w=100&blur=10&q=50`,
                width: card.image1.dimensions.width,
                height: card.image1.dimensions.height
              }} alt={card.image1.alt}/></a>
            }) : null }
        </SocialBoxes>
      </Inner>
    </Wrapper>
  )
}

export default OurSocial;