import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { NewsTicker as ManualTicker } from '@manualengineering/react-news-ticker';
import CSSNewsTicker from './CSSNewsTicker';
import ArrowButton from '../ArrowButton/ArrowButton';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NewsTicker = (props) => {
  const { children, ctaLabel, ctaLink, speed } = props;


  return (
    <Wrapper>
      <CSSNewsTicker speed={speed}>{children}</CSSNewsTicker>
      <Link to={ctaLink}><ArrowButton>{ctaLabel}</ArrowButton></Link>
    </Wrapper>
  )

}

export default NewsTicker;