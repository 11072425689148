import React, { useState, useRef, useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import styled from 'styled-components';
import HtmlParser from '../HtmlParser/HtmlParser';
import { LazyBackgroundBlur } from '@manualengineering/react-lazyblur';
import ModuleDivider from '../ModuleDivider/ModuleDivider';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.section`
  position: relative;
  width: 100%;
`

const MissionMobileItems = styled.div` 
  margin: 27px 0 47px;
`

const OurMissionMobile = ({statements}) => {

  return (
    <Wrapper>
      <ModuleDivider>Our Mission</ModuleDivider>
      <MissionMobileItems>
      { statements.map((item, index) => {
        return <MissionMobileItem src={item.image1.url}>{item.statement}</MissionMobileItem>
      }) }
      </MissionMobileItems>
    </Wrapper>
  )
}

export default OurMissionMobile;



const ItemWrapper = styled.div`
  position: relative;
  z-index: 1;
  padding-bottom: 60%;
  width: 100%;
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin: 0 0 20px 0;
  }
`

const Inner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  position: relative;
  width: 100%;
`

const Statement = styled.div`
  ${fontstack.default}
  ${type('m-heading')}
  //border-bottom: 0.2vw solid ${props => props.active ? '#383B23' : '#383B2300'};
  font-weight: 300;
  text-align: center;
  color: ${props => props.active ? '#F3EFD7' : '#383B23'};
  position: relative;
  transition: all .4s ease;
  z-index: 1;
`

const BackgroundWrapper = styled.div` 
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  overflow: hidden;
  opacity: ${props => props.active ? '1' : '0'};
  transition: all .4s ease;
`

const MissionMobileItem = React.forwardRef((props,ref) => {
  const { children, src, ...rest } = props;
  const WrapperRef = useRef();
  const Controller = useRef();
  const [ Active, setActive] = useState(false);

  useEffect(() => {

    if(WrapperRef.current) {
      Controller.current = ScrollTrigger.create({
        trigger: WrapperRef.current,
        start: "top center",
        end: "bottom center",
        markers: false,
        onToggle: self => setActive(self.isActive)
      });

    }

    return () => {
      Controller.current.kill();
    }
  }, [WrapperRef] )

  return (
    <ItemWrapper ref={WrapperRef} {...rest}>
      <Inner>
      <Statement active={Active}>
        <HtmlParser>
        { children }
        </HtmlParser>
      </Statement>
      </Inner>
      <BackgroundWrapper active={Active}>
        <LazyBackgroundBlur src={src}></LazyBackgroundBlur>
      </BackgroundWrapper>
    </ItemWrapper>
    
  )
})

export { MissionMobileItem };