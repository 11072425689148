import React from 'react';
import styled from 'styled-components';
import { fontstack } from '../../utils/fontstack';
import { type } from '../../utils/type';
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  position: relative;
  white-space: pre;
`

const Label = styled.div`
  ${fontstack.default};
  ${type('m-heading')}
  font-weight: 300;
  color: #383B23;
  text-transform: uppercase;

  ${media.large`
    ${type('heading')}
    font-weight: 300;
  `}
`

const Count = styled.div`
  ${fontstack.default};
  ${type('m-body')}
  color: #383B23;

  ${media.large`
    ${type('body')}
  `}
`
const NewsTickerItem = (props) => {
  const { count, children } = props;


  return (
    <Wrapper>
      <Count>{count}</Count>
      <Label>{children}</Label>
    </Wrapper>
  )

}

export default NewsTickerItem;